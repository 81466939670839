/* eslint-disable react/no-unescaped-entities */
import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AppLogo from "../../hoc/AppLogo";
import SubTitle from "../../hoc/SubTitle";
import HistoryData from "../Steps/HistoryData";
import OfferParameteres from "../Steps/OfferParameters";
import OfferDetails from "../Steps/OfferDetails";
import { changeStepOneShow } from "../../store/actions";
import { GETAltis } from "../../apis/ServerApis";
import {
  altisUrl,
  costingPrices,
  userID,
  selectedClientPartner,
  roleID,
  applicationSetting,
  offerData,
  firstStep,
  guId,
  forecastIds,
  showSummerWinter,
} from "../../constants";
import {
  handleResponseCode,
  handleException,
  handleFailure,
} from "../../utility";

// eslint-disable-next-line react/prefer-stateless-function
class Home extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      navigateToMyAccount: false,
      navigateToMyOffers: false,
      navigateToMyContacts: false,
      stepOneExpanded: true,
      stepTwoExpanded: false,
      stepThreeExpanded: false,
    };
  }

  componentDidMount() {
    console.log(
      "--------------------------ComponentDidMount-------------------------------------------",
    );
    localStorage.removeItem(costingPrices);
    localStorage.removeItem(applicationSetting);
    localStorage.removeItem(firstStep);

    localStorage.removeItem(guId);
    localStorage.removeItem(forecastIds);
    localStorage.removeItem(offerData);
    localStorage.removeItem(showSummerWinter);

    if (JSON.parse(localStorage.getItem(selectedClientPartner)) !== null) {
      this.callGetCostingPrices();
      this.callApplicationSettingForParticualPartner();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render

    if (nextProps.stepTwoShow !== this.state.stepTwoExpanded) {
      this.setState({
        stepTwoExpanded: nextProps.stepTwoShow,
        stepOneExpanded: !nextProps.stepTwoShow,
      });
    }

    if (nextProps.stepThreeShow !== this.state.stepThreeExpanded) {
      if (nextProps.stepTwoShow === false) {
        this.setState({
          stepThreeExpanded: nextProps.stepThreeShow,
          stepTwoExpanded: nextProps.stepThreeShow,
          stepOneExpanded: !nextProps.stepThreeShow,
        });
      } else if (
        nextProps.stepTwoShow === true &&
        nextProps.stepThreeShow === false
      ) {
        this.setState({
          stepThreeExpanded: nextProps.stepThreeShow,
          stepTwoExpanded: !nextProps.stepThreeShow,
          stepOneExpanded: nextProps.stepThreeShow,
        });
      } else {
        this.setState({
          stepThreeExpanded: nextProps.stepThreeShow,
          stepTwoExpanded: !nextProps.stepThreeShow,
          stepOneExpanded: !nextProps.stepThreeShow,
        });
      }
    }
  }

  callGetCostingPrices = () => {
    const url = `${altisUrl}costing/prices`;

    GETAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          localStorage.setItem(costingPrices, JSON.stringify(response.data));
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  callApplicationSettingForParticualPartner = () => {
    // Only Admin can set settingParameter of Partner
    // So If Login User = Admin then call an api by selectedPartnerId from dropdown selection
    // If Login user = Partner then call an api by loginPartnerId(userID)
    // because we can't set client's setting parameter so it same as Partner from their belonging partner

    const userId = localStorage.getItem(userID);
    const partnerId = JSON.parse(localStorage.getItem(selectedClientPartner))
      .id;
    let url = `${altisUrl}applicationSettings?user_id=${partnerId}`; // selected Partner Id
    // If Login user = Partner then
    if (localStorage.getItem(roleID) === "2") {
      url = `${altisUrl}applicationSettings?user_id=${userId}`;
    }

    GETAltis(url)
      .then((response) => {
        console.log(response);
        if (handleResponseCode(response)) {
          console.log(response.data);
          localStorage.setItem(
            applicationSetting,
            JSON.stringify(response.data),
          );
        } else {
          handleFailure(response);
        }
      })
      .catch((error) => {
        handleException(error);
      });
  };

  handleMonCompte = () => {
    this.setState({
      navigateToMyAccount: true,
    });
  };

  handleMesOffers = () => {
    this.setState({
      navigateToMyOffers: true,
    });
  };

  handleMesContacts = () => {
    this.setState({
      navigateToMyContacts: true,
    });
  };

  stepOneAccordianChange = (e, expanded) => {
    console.log("Expande : ", expanded);
    if (expanded) {
      this.setState({
        stepOneExpanded: expanded,
        stepTwoExpanded: !expanded,
        stepThreeExpanded: !expanded,
      });
    }
    // } else {
    //     this.setState({
    //         stepOneExpanded: !expanded,
    //         stepTwoExpanded: expanded,
    //         stepThreeExpanded: expanded,
    //     });
    // }
  };

  stepTwoAccordianChange = (e, expanded) => {
    if (expanded) {
      this.setState({
        stepTwoExpanded: expanded,
        stepOneExpanded: !expanded,
        stepThreeExpanded: !expanded,
      });
    } else {
      this.setState({
        stepTwoExpanded: expanded,
        stepOneExpanded: !expanded,
        stepThreeExpanded: expanded,
      });
    }
  };

  stepTreeAccordianChange = (e, expanded) => {
    if (expanded) {
      this.setState({
        stepThreeExpanded: expanded,
        stepTwoExpanded: !expanded,
        stepOneExpanded: !expanded,
      });
    } else {
      this.setState({
        stepThreeExpanded: expanded,
        stepTwoExpanded: expanded,
        stepOneExpanded: !expanded,
      });
    }
  };

  render() {
    const renderAdminButtons = localStorage.getItem(roleID) === "1";
    const selectedClientPartnerTitle = localStorage.getItem(roleID) === "2" ? "Client sélectionné" : "Partenaire sélectionné";
    // If LoginUser = Admin so show logo of SelectedPartnerLogo (from dropdown)
    // If LoginUser = Partner so show logo of Login Partner
    let partnerLogo = "";
    if (!renderAdminButtons) {
      if (this.props.userDetail) {
        partnerLogo = this.props.userDetail.logo;
      }
    } else {
      partnerLogo = JSON.parse(localStorage.getItem(selectedClientPartner))
        .logo;
    }

    if (this.state.navigateToMyAccount) {
      return <Redirect to="/myaccount" />;
    }

    if (this.state.navigateToMyOffers) {
      return <Redirect to="myoffer" />;
    }

    if (this.state.navigateToMyContacts) {
      return <Redirect to="mycontacts" />;
    }

    const selectedPartnerData = JSON.parse(
      localStorage.getItem(selectedClientPartner),
    );
    if (selectedPartnerData === null) {
      return <Redirect to="userselection" />;
    }

    let offerDetails = localStorage.getItem(offerData);
    if (offerDetails !== null) {
      offerDetails = JSON.parse(offerDetails);
    }

    return (
      <div>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="left"
          style={{ minHeight: "100vh" }}
          className="mainContainer"
        >
          <div className="headerContainer">
            <div className="headerLeftPart">
              <div className="headerIneraLogo">
                <div className="ineraLogo">
                  <AppLogo />
                  <SubTitle titleText="COSTING PLATFORM" />
                </div>
              </div>
              <div className="headerAltisLogo">
                <div
                  className="altisLogoInHeader"
                  style={{ backgroundImage: `url(${partnerLogo})` }}
                />
              </div>
            </div>
            <div className="headerRightPart">
              
              <div>
              <div className="partnerDisplay">{selectedClientPartnerTitle} : {JSON.parse(localStorage.getItem(selectedClientPartner)).name} </div>
                <Button
                  variant="outlined"
                  className="menuButton"
                  onClick={this.handleMonCompte}
                >
                  Mon compte
                </Button>
                <Button
                  variant="outlined"
                  className="menuButton"
                  onClick={this.handleMesOffers}
                >
                  Mes Offres
                </Button>
                <Button
                  variant="outlined"
                  className="menuButton"
                  onClick={this.handleMesContacts}
                >
                  {renderAdminButtons ? "Partenaires" : "Mes Contacts"}
                </Button>
              </div>
            </div>
          </div>

          <div
            className="stepsContainer"
            style={{
              marginBottom: `${this.state.stepOneExpanded ? 6 : 2}em`,
            }}
          >
            <Accordion
              className="stepsAccordion"
              onChange={this.stepOneAccordianChange}
              expanded={this.state.stepOneExpanded}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="stepNumber">1</span>
                <span className="stepTitle">Données historiques</span>
              </AccordionSummary>
              <AccordionDetails className="stepAccordionDetails">
                <HistoryData />
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="stepsContainer"
            style={{
              marginBottom: `${this.state.stepTwoExpanded ? 6 : 2}em`,
            }}
          >
            <Accordion
              className="stepsAccordion"
              onChange={this.stepTwoAccordianChange}
              disabled={!this.props.stepTwoShow}
              expanded={this.state.stepTwoExpanded}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="stepNumber">2</span>
                <span className="stepTitle">Paramètres de l'offre</span>
              </AccordionSummary>
              <AccordionDetails className="stepAccordionDetails">
                <OfferParameteres />
              </AccordionDetails>
            </Accordion>
          </div>

          <div
            className="stepsContainer"
            style={{
              minWidth: "75em",
              marginBottom: `${this.state.stepThreeExpanded ? 6 : 2}em`,
            }}
          >
            <Accordion
              className="stepsAccordion blueStep"
              onChange={this.stepTreeAccordianChange}
              disabled={!this.props.stepThreeShow}
              expanded={this.state.stepThreeExpanded}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="stepNumber">3</span>
                <span className="stepTitle">Calcul de l’offre</span>
              </AccordionSummary>
              <AccordionDetails className="stepAccordionDetails">
                <OfferDetails offerDetails={offerDetails} />
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
      </div>
    );
  }
}

// Get step hide show object from Redux Store
const mapStateToProps = (state) => ({
  userDetail: state.user.userDetail,
  stepOneShow: state.stepHideShowReducer.stepOneShow,
  stepTwoShow: state.stepHideShowReducer.stepTwoShow,
  stepThreeShow: state.stepHideShowReducer.stepThreeShow,
});

// call an action for redux
const mapDispatchToProps = (dispatch) => ({
  onChangeStepOneShow: (isShow) => dispatch(changeStepOneShow(isShow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

Home.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  userDetail: PropTypes.objectOf(PropTypes.any),
  // stepOneShow: PropTypes.bool,
  stepTwoShow: PropTypes.bool,
  stepThreeShow: PropTypes.bool,
};

Home.defaultProps = {
  userDetail: null,
  // stepOneShow: true,
  stepTwoShow: false,
  stepThreeShow: false,
};
