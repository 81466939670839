import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from "prop-types";

import CustomInputField from "../../hoc/CustomInputField";
import BlueButton from "../../hoc/BlueButton";
import {
    checkEmailisValid,
    handleResponseCode,
    handleException,
    showAlert,
    handleFailure,
} from "../../utility";
import { userID, altisUrl, cantonList } from "../../constants";
import { POSTAltis, PUTAltis } from "../../apis/ServerApis";

class AddClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientName: "",
            errorclientName: false,
            clientNameErrorMessage: "",
            contactName: "",
            errorcontactName: false,
            contactNameErrorMessage: "",
            contactEmail: "",
            errorContactEmail: false,
            contactEmailErrorMessage: "",
            contactNumber: "",
            errorcontactNumber: false,
            contactNumberErrorMessage: "",
            address: "",
            errorAddress: false,
            addressErrorMessage: "",
            street: "",
            city: "",
            cp: "",
            canton: "",
            site: "",
            errorSite: false,
            siteErrorMessage: "",
            meteringCode: "",
            errorMeteringCode: false,
            meteringCodeErrorMessage: "",
        };
    }

    componentDidMount() {
        if (this.props.contactObj) {
            const clientData = this.props.contactObj;
            this.setState({
                clientName: clientData.name,
                contactName: clientData.contact_name,
                contactEmail: clientData.email,
                contactNumber: clientData.phone,
                address: clientData.address,
                street: clientData.street,
                city: clientData.city,
                cp: clientData.cp,
                canton: clientData.canton,
                site: clientData.site,

                meteringCode: clientData.metering_code,
            });
        }
    }

    // Input field handle - ClientName
    handleClientNameChange = (newValue) => {
        this.setState({
            clientName: newValue,
            errorclientName: false,
            clientNameErrorMessage: "",
        });
    };

    // Input field handle - ContactName
    handleContactNameChange = (newValue) => {
        this.setState({
            contactName: newValue,
            errorcontactName: false,
            contactNameErrorMessage: "",
        });
    };

    // Input field handle - ContactEmail
    handleContactEmailChange = (newValue) => {
        this.setState({
            contactEmail: newValue,
            errorContactEmail: false,
            contactEmailErrorMessage: "",
        });
    };

    // Input field handle - ContactNumber
    handleContactNumberChange = (newValue) => {
        this.setState({
            contactNumber: newValue,
            errorcontactNumber: false,
            contactNumberErrorMessage: "",
        });
    };

    // Input field handle - Address
    handleAddressChange = (newValue) => {
        this.setState({
            address: newValue,
            errorAddress: false,
            addressErrorMessage: "",
        });
    };

    // Input field handle - Street name
    handleStreetNameChange = (newValue) => {
        this.setState({
            street: newValue,
        });
    };

    // Input field handle - City
    handleCityChange = (newValue) => {
        this.setState({
            city: newValue,
        });
    };

    // Input field handle - CP
    handleCPChange = (newValue) => {
        this.setState({
            cp: newValue,
        });
    };

    // Input field handle - Canton
    handleCantonChange = (newValue) => {
        this.setState({
            canton: newValue,
        });
    };

    // Input field handle - Site
    handleSiteChange = (newValue) => {
        this.setState({
            site: newValue,
            errorSite: false,
            siteErrorMessage: "",
        });
    };

    // Input field handle - Metering code
    handleMeteringCodeChange = (newValue) => {
        this.setState({
            meteringCode: newValue,
            errorMeteringCode: false,
            meteringCodeErrorMessage: "",
        });
    };

    // call an api for Add Client & Check validation
    handleContinue = () => {
        if (this.state.clientName === "") {
            this.setState({
                errorclientName: true,
                clientNameErrorMessage:
                    "Le nom du client ne peut pas être vide.",
            });
            return;
        }

        if (this.state.contactName === "") {
            this.setState({
                errorcontactName: true,
                contactNameErrorMessage:
                    "Le nom du contact ne peut pas être vide.",
            });
            return;
        }
        if (this.state.contactEmail === "") {
            this.setState({
                errorContactEmail: true,
                contactEmailErrorMessage: "L'e-mail ne peut pas être vide.",
            });
            return;
        }

        if (!checkEmailisValid(this.state.contactEmail)) {
            this.setState({
                errorContactEmail: true,
                contactEmailErrorMessage: "L'email n'est pas valide",
            });
            return;
        }

        if (this.state.contactNumber === "") {
            this.setState({
                errorcontactNumber: true,
                contactNumberErrorMessage:
                    "Numéro de contact ne peut pas être vide.",
            });
            return;
        }

        if (
            this.state.address === "" ||
            this.state.street === "" ||
            this.state.city === "" ||
            this.state.cp === "" ||
            this.state.canton === ""
        ) {
            this.setState({
                errorAddress: true,
                addressErrorMessage:
                    "L'adresse, la rue, la ville, le cp et le canton ne peuvent pas être vides.",
            });
            return;
        }

        if (this.state.site === "") {
            this.setState({
                errorSite: true,
                siteErrorMessage: "le site ne peut pas être vide.",
            });
            return;
        }

        if (this.state.meteringCode === "") {
            this.setState({
                errorMeteringCode: true,
                meteringCodeErrorMessage:
                    "le code de mesure ne peut pas être vide.",
            });
            return;
        }

        const clientParams = {
            user_id: localStorage.getItem(userID),
            name: this.state.clientName,
            contact_name: this.state.contactName,
            email: this.state.contactEmail,
            phone: this.state.contactNumber,
            site: this.state.site,
            metering_code: this.state.meteringCode,
            address: this.state.address,
            street: this.state.street,
            city: this.state.city,
            canton: this.state.canton,
            cp: this.state.cp,
        };

        if (this.props.contactObj) {
            const url = `${altisUrl}clients/${this.props.contactObj.id}`;
            this.EditClientApiCall(url, clientParams);
        } else {
            const url = `${altisUrl}clients`;
            this.AddClientApiCall(url, clientParams);
        }
    };

    // Api call for add new client
    AddClientApiCall = (url, clientParams) => {
        POSTAltis(url, clientParams)
            .then((response) => {
                console.log(response);
                if (handleResponseCode(response)) {
                    console.log(response.data);
                    if (response && response.data && response.data.id) {
                        if(window.location.hash === "#/mycontacts")
                        {
                            showAlert("Succès", "Client ajouté avec succès");       
                             // eslint-disable-next-line react/prop-types
                            this.props.userCreatedSuccessfully();
                        } else {
                             // eslint-disable-next-line no-restricted-globals
                        this.userCreatedSuccessfully(response.data);
                        }
                       
                    }
                } else {
                    handleFailure(response);
                }
            })
            .catch((error) => {
                handleException(error);
            });
    };

    // Api call for Edit client
    EditClientApiCall = (url, clientParams) => {
        PUTAltis(url, clientParams)
            .then((response) => {
                console.log(response);
                if (handleResponseCode(response)) {
                    console.log(response.data);
                    showAlert("Succès", "Données enregistrées avec succès");
                    // eslint-disable-next-line react/prop-types
                    this.props.closeBtnClick();
                } else {
                    handleFailure(response);
                }
            })
            .catch((error) => {
                handleException(error);
            });
    };

    userCreatedSuccessfully = (userData) => {
        // eslint-disable-next-line react/prop-types
        this.props.userCreatedSuccessfully(userData);
    };

    closeClicked = () => {
        console.log(this.props);
        // eslint-disable-next-line react/prop-types
        this.props.closeBtnClick();
    };

    render() {
        let title = "Ajouter un nouveau client"
       if(this.props.contactObj){
           title = "Modifier un client";
       }
        
        return (
            <div>
                <IconButton
                    color="primary"
                    aria-label="close"
                    onClick={this.closeClicked}
                    className="closeBtn"
                >
                    <CloseIcon />
                </IconButton>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: "100vh" }}
                    className="formPopupContainer"
                >
                    <div className="popupTitle">{title}</div>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">Nom du client*</div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName=""
                                fieldType="clientName"
                                fieldValue={this.state.clientName}
                                isError={this.state.errorclientName}
                                errorMessage={this.state.clientNameErrorMessage}
                                textChange={this.handleClientNameChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">
                                Nom du contact*
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName=""
                                fieldType="contactName"
                                fieldValue={this.state.contactName}
                                isError={this.state.errorcontactName}
                                errorMessage={
                                    this.state.contactNameErrorMessage
                                }
                                textChange={this.handleContactNameChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">
                                E-mail du contact*
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName=""
                                fieldType="contactEmail"
                                fieldValue={this.state.contactEmail}
                                isError={this.state.errorContactEmail}
                                errorMessage={
                                    this.state.contactEmailErrorMessage
                                }
                                textChange={this.handleContactEmailChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">
                                Numéro de Tél. du contact*
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName="+41 xx xxx xx xx"
                                fieldType="contactNumber"
                                fieldValue={this.state.contactNumber}
                                isError={this.state.errorcontactNumber}
                                errorMessage={
                                    this.state.contactNumberErrorMessage
                                }
                                textChange={this.handleContactNumberChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">Adresse*</div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName="Rue"
                                fieldType="Street"
                                fieldValue={this.state.street}
                                textChange={this.handleStreetNameChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="right"
                        justify="center"
                        style={{ marginTop: "0.2em" }}
                    >
                        <Grid item xs={6} sm={4} />
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName="Numéro"
                                fieldType="Address"
                                fieldValue={this.state.address}
                                isError={this.state.errorAddress}
                                errorMessage={this.state.addressErrorMessage}
                                textChange={this.handleAddressChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="right"
                        justify="center"
                        style={{ marginTop: "0.2em" }}
                    >
                        <Grid item xs={6} sm={4} />
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName="Ville"
                                fieldType="City"
                                fieldValue={this.state.city}
                                textChange={this.handleCityChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="right"
                        justify="center"
                        style={{ marginTop: "0.2em" }}
                    >
                        <Grid item xs={6} sm={4} />
                        <Grid item xs={3} sm={4}>
                            <CustomInputField
                                fieldName="CP"
                                fieldType="cp"
                                fieldValue={this.state.cp}
                                textChange={this.handleCPChange}
                            />
                        </Grid>
                        <Grid item xs={3} sm={4}>
                        <Autocomplete
                            options={cantonList}
                            id="controlled-demo"
                            value={this.state.canton}
                            onChange={(event, newValue) => {
                                this.handleCantonChange(newValue);
                            }}
                            renderInput={(params) => (
                                 // eslint-disable-next-line react/jsx-props-no-spreading
                                 <TextField {...params} label="Canton" className="inputFields" size="small" />
                            )}
                        />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">site*</div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName=""
                                fieldType="site"
                                fieldValue={this.state.site}
                                isError={this.state.errorSite}
                                errorMessage={this.state.siteErrorMessage}
                                textChange={this.handleSiteChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={4}>
                            <div className="formLabelPopup">code métrique*</div>
                        </Grid>
                        <Grid item xs={6} sm={8}>
                            <CustomInputField
                                fieldName="CH100440123450000000000000719310O"
                                fieldType="meteringCode"
                                fieldValue={this.state.meteringCode}
                                isError={this.state.errorMeteringCode}
                                errorMessage={
                                    this.state.meteringCodeErrorMessage
                                }
                                textChange={this.handleMeteringCodeChange}
                            />
                        </Grid>
                    </Grid>
                    <div className="formLabelPopup" style={{ marginTop: "1em" }}>Tous les champs marqués d’une * sont obligatoires</div>
                    <div style={{ marginTop: "0.5em" }}>
                        <BlueButton
                            btnName="Valider"
                            btnClick={this.handleContinue}
                        />
                    </div>
                </Grid>
            </div>
        );
    }
}

AddClient.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    contactObj: PropTypes.objectOf(PropTypes.any),
};

AddClient.defaultProps = {
    contactObj: null,
};

export default AddClient;
