import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";

import BlueButton from "../../hoc/BlueButton";
import {
    handleResponseCode,
    handleException,
    showAlert,
    handleFailure,
} from "../../utility";
import { altisUrl, applicationSetting } from "../../constants";
import { POSTAltis } from "../../apis/ServerApis";

class Settings extends Component {
    constructor(props) {
        super(props);
        console.log("SettingObj :", this.props.settingsObj);
        this.state = {
            searchDays: "",
            factor: "",
            precisionOfForcast: "",
            riskOfConsumption: "",
            priceRisk: "",
            coverageAccuracy: "",
            overcostFactor: "",
            sigmaBS: "",
        };
    }

    componentDidMount() {
        console.log("Called");
        if (this.props.settingsObj) {
            this.setState({
                searchDays: this.props.settingsObj.search_reference_days,
                factor: this.props.settingsObj.outlier_factor,
                precisionOfForcast: this.props.settingsObj.forecasts_precision,
                riskOfConsumption: this.props.settingsObj.consumption_risk,
                priceRisk: this.props.settingsObj.price_risk,
                coverageAccuracy: this.props.settingsObj.coverage_accuracy,
                overcostFactor: this.props.settingsObj.overcost_factor,
                sigmaBS: this.props.settingsObj.sigma_Bs,
            });
        }
    }

    // Input field handle - Search Days
    handleSearchDaysChange = (e) => {
        this.setState({
            searchDays: e.target.value,
        });
    };

    // Input field handle - Outlier Factor
    handleOutlierFactorChange = (e) => {
        this.setState({
            factor: e.target.value,
        });
    };

    // Input field handle - precisionOfForcast
    handlePrecisionOfForcastChange = (e) => {
        this.setState({
            precisionOfForcast: e.target.value,
        });
    };

    // Input field handle - riskOfConsumption
    handleRiskOfConsumptionChange = (e) => {
        this.setState({
            riskOfConsumption: e.target.value,
        });
    };

    // Input field handle - priceRisk
    handlePriceRiskChange = (e) => {
        this.setState({
            priceRisk: e.target.value,
        });
    };

    // Input field handle - coverageAccuracy
    handleCoverageAccuracyChange = (e) => {
        this.setState({
            coverageAccuracy: e.target.value,
        });
    };

    // Input field handle - overcostFactor
    handleOvercostFactorChange = (e) => {
        this.setState({
            overcostFactor: e.target.value,
        });
    };

    // Input field handle - SigmaBS
    handleSigmaBSChange = (e) => {
        this.setState({
            sigmaBS: e.target.value,
        });
    };

    handleContinue = () => {
        const url = `${altisUrl}applicationSettings`;
        console.log("this.props: ", this.props);
        const clientParams = {
            id: this.props.settingsObj.user_id,
            search_reference_days: this.state.searchDays,
            outlier_factor: this.state.factor,
            forecasts_precision: this.state.precisionOfForcast,
            consumption_risk: this.state.riskOfConsumption,
            price_risk: this.state.priceRisk,
            coverage_accuracy: this.state.coverageAccuracy,
            overcost_factor: this.state.overcostFactor,
            sigma_Bs: this.state.sigmaBS,
        };
        localStorage.removeItem(applicationSetting);
        POSTAltis(url, clientParams)
            .then((response) => {
                console.log(response);
                if (handleResponseCode(response)) {
                    console.log(response.data);
                    if (response.data) {
                        localStorage.setItem(
                            applicationSetting,
                            JSON.stringify(response.data)
                        );
                        showAlert("Succès", "Données enregistrées avec succès");
                        // eslint-disable-next-line react/prop-types
                        this.props.closeBtnClick();
                    }
                    // if (response && response.data && response.data.id) {
                    //     self.userCreatedSuccessfully(response.data);
                    // }
                } else {
                    handleFailure(response);
                }
            })
            .catch((error) => {
                handleException(error);
            });
    };

    settingsUpdatedSuccessfully = (userData) => {
        // eslint-disable-next-line react/prop-types
        this.props.userCreatedSuccessfully(userData);
    };

    closeClicked = () => {
        console.log(this.props);
        // eslint-disable-next-line react/prop-types
        this.props.closeBtnClick();
    };

    render() {
        return (
            <div>
                <IconButton
                    color="primary"
                    aria-label="close"
                    onClick={this.closeClicked}
                    className="closeBtn"
                >
                    <CloseIcon />
                </IconButton>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: "100vh" }}
                    className="settingsPopupContainer"
                >
                    <div className="settingTitle">Paramètres d’application</div>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                    >
                        <Grid item xs={6} sm={8}>
                            <div className="settingsFormLbl">
                                Recherche des jours de réf. (avant/après){" "}
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <OutlinedInput
                                className="manualInput settingsInputField"
                                value={this.state.searchDays}
                                onChange={this.handleSearchDaysChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        jours
                                    </InputAdornment>
                                }
                                labelWidth={0}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={8}>
                            <div className="settingsFormLbl">
                                Facteur de valeurs aberrantes
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <OutlinedInput
                                className="manualInput settingsInputField"
                                value={this.state.factor}
                                onChange={this.handleOutlierFactorChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        -
                                    </InputAdornment>
                                }
                                labelWidth={0}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={8}>
                            <div className="settingsFormLbl">
                                Précision des previsions
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <OutlinedInput
                                className="manualInput settingsInputField"
                                value={this.state.precisionOfForcast}
                                onChange={this.handlePrecisionOfForcastChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                }
                                labelWidth={0}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={8}>
                            <div className="settingsFormLbl">
                                Risque de consommatlon
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <OutlinedInput
                                className="manualInput settingsInputField"
                                value={this.state.riskOfConsumption}
                                onChange={this.handleRiskOfConsumptionChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                }
                                labelWidth={0}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={8}>
                            <div className="settingsFormLbl">
                                Risque de prix / HFC
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <OutlinedInput
                                className="manualInput settingsInputField"
                                value={this.state.priceRisk}
                                onChange={this.handlePriceRiskChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                }
                                labelWidth={0}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={8}>
                            <div className="settingsFormLbl">
                                Précision couverture
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <OutlinedInput
                                className="manualInput settingsInputField"
                                value={this.state.coverageAccuracy}
                                onChange={this.handleCoverageAccuracyChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        MW
                                    </InputAdornment>
                                }
                                labelWidth={0}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={8}>
                            <div className="settingsFormLbl">
                                Facteur surcoût EA
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <OutlinedInput
                                className="manualInput settingsInputField"
                                value={this.state.overcostFactor}
                                onChange={this.handleOvercostFactorChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                }
                                labelWidth={0}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="left"
                        justify="center"
                        style={{ marginTop: "1em" }}
                    >
                        <Grid item xs={6} sm={8}>
                            <div className="settingsFormLbl">Sigma BS</div>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <OutlinedInput
                                className="manualInput settingsInputField"
                                value={this.state.sigmaBS}
                                onChange={this.handleSigmaBSChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                }
                                labelWidth={0}
                            />
                        </Grid>
                    </Grid>

                    <div style={{ marginTop: "1em" }}>
                        <BlueButton
                            btnName="Valider"
                            btnClick={this.handleContinue}
                        />
                    </div>
                </Grid>
            </div>
        );
    }
}

Settings.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    settingsObj: PropTypes.objectOf(PropTypes.any),
};

Settings.defaultProps = {
    settingsObj: null,
};

export default Settings;
